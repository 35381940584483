.authBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    116.82deg,
    rgba(209, 209, 214, 0.8) 0%,
    rgba(209, 209, 214, 0.8) 15.48%,
    rgba(0, 161, 222, 0.4) 52.2%,
    rgba(209, 209, 214, 0.8) 83.66%,
    rgba(209, 209, 214, 0.8) 100%
  );
}

.authCard {
  height: 70vh;
  width: 55vw;
  padding: 3rem 0;
  // background-color: #F5F5F5;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#f5f5f5 60%, #bae0ef);
}

.authCardContent {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0%;
  left: 0%;
  padding: 4rem 0;
  display: flex;
}

.authEllipse {
  height: 370px;
  width: 500px;
  border-radius: 50%;
  margin-top: -20%;
  margin-left: -16%;
  background: linear-gradient(191.1deg, rgba(0, 161, 222, 0.35) 34.74%, rgba(0, 161, 222, 0) 91.8%);
}

.loginContainer {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loginContainerTwo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    315deg,
    rgba(178, 230, 255, 0.3) 26%,
    rgba(25, 118, 209, 0.41082370448179273) 52%,
    rgba(255, 255, 255, 0.6) 76%
  );
}

.inputFields {
  width: 80%;
  border-radius: 30px;
  margin: 1rem;
}

.centerText {
  text-align: center;
}

.loginText {
  font-size: 1.7rem;
  font-weight: bold;
  border-bottom: 2px solid #2200ff;
}

.loginBox {
  width: 30rem;
  min-height: 30rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginBoxContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.loginButton {
  background-color: darkBlue;
  color: white;
  width: 80%;
  margin: 1rem auto;
}

.spaceBetween {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
