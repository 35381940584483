.chatContainer {
    height: 52vh;
    width: 100%;
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    overflow-y: scroll;
  }

  .chatContainer .MuiTabs-root {
    min-height: auto !important;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgb(214, 214, 214);
}

.chatContainer .Mui-selected {
    color: black !important;
    border-bottom: none !important;
    font-weight: bold;
}

.chatContainer .MuiTab-root {
    min-width: auto !important;
    min-height: auto !important;
    font-size: 0.8rem !important;
    height: auto !important;
    font-weight: bold;
    text-transform: none !important;
    padding: 6px 5px !important;
}