$darkBlue: #4084d8;
$primaryColor: #4084d8;
$primaryGrey: #c4c4c4;
$primaryGrey2: #f8f8f8;
$primaryGrey3: #ebebeb;
$textColor: #545252;
$secondaryTextColor: #797979;
$deleteRed2: #f9320d;
$primaryPurple: #2c358d;
$primaryBackground: #f4f5f7;
$primaryGreen: #00a44b;
$lightBlue: #82b7f8;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}

.buttonContainer {
  width: 95%;
}

.display_flex {
  display: flex;
  align-items: center;
}

.filterButtonsContainer {
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.newva 
.formButton {
  background-color: $darkBlue !important;
  color: white !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  margin-bottom: 1.5rem !important;
  border-radius: 0.5rem !important;
  width: 94%;
  &:hover {
    background-color: $darkBlue !important;
  }
}

.filterButton {
  background-color: black !important;
  color: white !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  margin-right: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 0.5rem !important;
  width: 100%;
  &:hover {
    background-color: black !important;
  }
}

.cancelButton {
  background-color: white !important;
  color: black !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  margin-left: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  border: 2px solid grey !important;
  border-radius: 0.5rem !important;
  width: 100%;
  &:hover {
    background-color: white !important;
    border: 2px solid black !important;
  }
}

.loadingContainer {
  height: 23rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formHeader {
  height: 3rem;
  font-size: 2rem;
  padding: 16px 0;
  border-bottom: 2px solid #e0e0e0;
}

.formHeaderText {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  text-align: center !important;
}

.formContent {
  padding: 16px 20px !important;
  width: 34rem;
}

.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.circularProgress {
  color: white;
  height: 7px;
  width: 10px;
}

.documentCategory {
  background-color: white !important;
  padding: 1rem !important;
  border-radius: 1rem !important;
}

.newPurchaseContainer {
  height: 90vh;
  width: 80vw;
}

.paper {
  min-width: 80vw !important;
  min-height: 85vh;
}

.updatePaper {
  min-width: 60vw !important;
  min-height: 85vh;
}

.genericForm {
  min-width: 55vw !important;
  min-height: 65vh;
}

.genericForm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.genericForm_column {
  margin: 0 2rem;
  font-family: Poppins;
}

.genericFormVertical {
  min-width: 35vw !important;
  min-height: 75vh;
}

.genericFormVertical_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.genericFormVertical_column {
  margin: 0 2rem;
}

.closeButton {
  cursor: pointer;
  background-color: $primaryGrey2;
  padding: 0.4rem;
  border-radius: 6px;
  &:hover {
    background-color: $primaryGrey3;
  }
}

.inputComponent {
  margin: 1rem 0px;
  border-radius: 12px;
}

.forms_input_horizontal {
  margin: 0.5rem 1.2rem;
  width: 43%;
  display: inline-block;
}

.forms_input_vertical {
  margin: 0.5rem 1.2rem 0.5rem 0;
  width: 90%;
  display: inline-block;
}

.label {
  font-size: 1rem !important;
  margin: 0.2rem 0.2rem 0.2rem 0.4rem !important;
  font-family: Poppins;
}

.input {
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  padding-left: 1rem;
  color: textColor;
  font-size: 0.8rem;
}

.inputSelect {
  height: 1.2rem;
  border-radius: 5px !important;
  border: 1px solid grey;
  background-color: $primaryGrey2;
  // padding: 0.2rem 0 0.2rem 1rem;
  padding: 0.5rem 0 0.5rem 1rem !important;
  width: 90%;
  color: textColor;
  font-size: 0.8rem !important;
}

.autoComplete_input {
  padding: 0rem;
}

.formSelect {
  border: 1px solid $primaryGrey;
  border-radius: 5px;
  width: 94%;
  font-size: 0.97rem !important;
  padding: 0.5rem 0 0.5rem 1rem !important;
  color: textColor;
  font-weight: bold;
  background-color: $primaryGrey2;
  color: $textColor;
}

.dateInput input {
  border: 1px solid $primaryGrey !important;
  border-radius: 5px !important;
  width: 100% !important;
  padding: 8px 15px !important;
  color: textColor;
  font-size: 0.8rem !important;
  font-weight: bold !important;
  background-color: $primaryGrey2 !important;
  color: $textColor !important;
}

.dateInputRoot {
  width: 100% !important;
}

.form_select_skeleton {
  height: 3rem !important;
}

.form_input {
  border: 1px solid $primaryGrey;
  border-radius: 5px;
  width: 100%;
  padding: 0.2rem 0 0.2rem 1rem;
  color: textColor;
  font-size: 0.8rem !important;
  font-weight: bold;
  background-color: $primaryGrey2;
  color: $textColor;
}

.textField_input {
  font-size: 0.97rem !important;
  padding: 0.5rem 0 0.5rem 1rem !important;
}

.confirmButton {
  background-color: $darkBlue;
  margin: 3rem 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.downloadExcelButton {
  background-color: $primaryGreen;
  margin: 3rem 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.form_title {
  margin-top: 1.5rem !important;
  font-weight: bold !important;
  font-size: 1.4rem !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

.confirmModal {
  min-width: 30vw;
  min-height: 65vh;
}

.confirmModal_Confirm {
  background-color: $deleteRed2 !important;
  padding: 0.5rem 3.5rem;
  margin-bottom: 1rem;
  text-transform: none;
  &:hover {
    background-color: $deleteRed2 !important;
  }
}

.confirmModal_title {
  font-size: 1.4rem !important;
  font-weight: bold !important;
  color: $textColor;
}

.confirmModal_text {
  font-size: 0.9rem;
  text-align: center;
}

.purchasesReceipt {
  border: 1px solid $primaryGrey;
  height: 25rem;
  width: 80%;
  padding-top: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  box-shadow: 2px 4px 5px $primaryGrey;
  overflow-y: scroll;
}

.purchasesReceipt_header {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0 0;
}

.receipt_text {
  font-size: 0.8rem !important;
}

.receipt_items_text {
  font-size: 0.7rem !important;
  font-weight: bold !important;
}

.centerText {
  text-align: center;
}

.receiptTotal {
  text-align: right;
  color: $primaryPurple;
  font-size: 0.8rem !important;
  font-weight: bolder;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.receipt_textStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25;
  color: $textColor;
}

.receipt_logo {
  max-width: 5rem;
  max-width: 5rem;
}

.receipt_table {
  margin: 0px 0.7rem;
  padding: 0 5px;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: 900;
}

.receipt_title {
  text-align: center;
  color: black;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;
}
.receiptItem {
  border-bottom: 1px dashed black;
  margin-top: 10px;
  margin-bottom: 3px;
  cursor: pointer;
}

.receiptActions {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

.receipt_save_btn {
  background: $primaryGreen !important;
  margin-left: 2rem !important;
}

.receipt_print_btn {
  background-color: white !important;
  color: $darkBlue !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  border: 2px solid grey !important;
  border-radius: 0.5rem !important;
  &:hover {
    background-color: $darkBlue !important;
    color: white !important;
    border: 2px solid $darkBlue !important;
  }
}

.greetingText {
  color: black;
  font-weight: semi-bold !important;
  font-size: 1.8rem !important;
  margin-top: 0.3rem !important;
  font-family: Poppins;
}

.myAppBar {
  width: 100%;
  height: 8vh;
  padding: 0.5rem 1rem;
  background-color: white;
  border-bottom: 1px solid rgb(218, 218, 218);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
}

.navTitle {
  color: #2c358d;
  font-weight: bold !important;
  margin-left: 2rem !important;
  font-size: 1rem !important;
}

.listItemSelected {
  color: white;
  background-color: #7dc22b !important;
}

.listItemSelected {
  border-bottom: 2px solid gray;
  background-color: $primaryBackground !important;

  .listItemText {
    color: black;
    font-weight: 800 !important;
  }
}

.root {
  font-size: 0.8rem;
  color: $secondaryTextColor;
  width: auto !important;
  padding-bottom: 3px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 0.3rem;

  &:hover {
    color: black;
  }
  &:selected {
   

    .listItemText {
      color: $lightBlue;
    }
  }
}

.form_action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.form_confirmButton {
  background-color: $primaryColor !important;
  margin: 1.5rem 0.5rem 1rem 0.5rem !important;
  padding: 0.3rem 2rem !important;
  width: 30%;
  border-radius: 1rem !important;
}

.form_cancelButton {
  background-color: white !important;
  margin: 1.5rem 0.5rem 1rem 0.5rem !important;
  padding: 0.3rem 2rem !important;
  width: 30%;
  border-radius: 1rem !important;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
}