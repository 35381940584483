$primaryBlue: #4084d8;
$primaryColor: #4084d8;
$primaryPurple: #2c358d;
$lightPurple: #4956ce;
$darkOrange: #ff6f43;
$lightOrange: #fa8865;
$primaryGreen: #00a44b;
$appleGreen: #10ca64;
$lightBlue: #82b7f8;
$lightBlue2: #d8e9fd;
$textColor: #545252;
$secondaryTextColor: #a7a6a6;
$darkBlue: #4084d8;
$primaryGrey: #f8f5f5;
$primaryBackground: #f4f5f7;
$deleteRed: #ed5e68;
$primaryColor: #4084d8;
$lightBlue: #1976d2;
$primaryBackground: #f4f4f9;
$primaryGrey4: #c4c4c4;
$primaryGrey2: #f8f8f8;
$primaryGrey3: #ebebeb;
$textColor: #545252;
$lightTextColor: #929292;
$deleteRed2: #f9320d;
$primaryPurple: #2c358d;
$primaryGreen: #00a44b;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}

.chartContainer {
  height: 60vh;
  width: 100%;
  background-color: white;
  margin: 0.5rem 0;
}

.cpointer {
  cursor: pointer;
}

.pCenter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pRight {
  width: 100%;
  display: flex;
  justify-content: end;
}

.chatContainer {
  height: 52vh;
  width: 100%;
  background-color: white;
  margin: 1rem;
}

.shopHeader {
  display: flex;
  justify-content: spaceBetween;
}

.grow {
  flex-grow: 1;
}

.menuButton {
  margin-right: 20px;
}

.title {
  display: none;
}

.statsCardContainer {
  height: 20vh;
  width: 100%;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
}

.dashboardStatCard {
  background-color: white;
  border-radius: 0.7rem;
  height: 6rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 8px 10px rgb(201, 201, 201);
}

.dashboard_skeleton_card {
  height: 13.3rem !important;
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dashboard_skeleton_cards {
  display: flex;
  justify-content: space-between;
}

.dashboard_card_left {
  background-color: $lightPurple;
  width: 3%;
}

.dashboard_card_right {
  width: 100%;
}

.dashboard_card_icon_container {
  display: flex;
  width: 100%;
  height: 70%;
}

.dashboard_card_main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard_card_metric {
  margin-left: 0.5rem !important;
  font-size: 1.2rem !important;
  font-weight: bolder !important;
}
 
.dashboard_card_label {
  color: #545252 !important;
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
}
f .orange_grdt {
  color: white;
  background-image: linear-gradient(to top right, $lightOrange, $darkOrange);
}

.purple_grdt {
  color: white;
  background-image: linear-gradient(to top right, $lightPurple, $primaryPurple);
}

.green_grdt {
  color: white;
  background-image: linear-gradient(to top right, $appleGreen, $primaryGreen);
}

.blue_grdt {
  color: white;
  background-image: linear-gradient(to top right, $lightBlue, $darkBlue);
}

.dashboardStatCard_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.metric_label {
  color: $primaryGrey;
  font-size: 0.8rem;
}

.main_metric {
  font-weight: bold;
  font-size: 1.1rem;
}

.search {
  position: relative;
  border-radius: 2rem;
  background-color: white;
  &:hover {
    background-color: white;
  }
  // margin-right: theme.spacing(2);
  margin-left: 0;
  width: 100%;
}

.searchIcon {
  padding: 10px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.navTitle {
  color: #2c358d;
  font-weight: bold !important;
  margin-top: 2rem !important;
}

.inputInput {
  padding: 10px;
  // vertical padding + font size from searchIcon
  padding-left: 10px;
  // transition: theme.transitions.create(width);
  width: 100%;
}

.sectionDesktop {
  display: none;
  margin-left: auto;
  margin-right: 2rem;
}

.sectionMobile {
  display: flex;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.salesTopActions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.salesTopActionsLeft {
  display: flex;
}

.salesTopActionsRight {
  display: flex;
}

.newSaleButton {
  background-color: $primaryBlue;
  color: white;
  margin-left: 1rem;
  &:hover {
    background-color: $primaryBlue;
  }
}

.normalButton {
  background-color: white;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  border: 2px solid transparent;
  &:hover {
    background-color: white;
    border: 2px solid $primaryBlue;
  }
}

.formButton {
  background-color: $primaryBlue;
  color: white;
  font-size: 0.7rem;
  padding: 4px 2rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}

.formHeader {
  background-color: $primaryBlue;
  width: 100%;
  height: 5rem;
  font-size: 4rem;
}

.formHeaderText {
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.formContent {
  padding-top: 2rem;
}

.itemsCategoryBox {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 1rem;

  padding: 0 10px;
}
.filterElements {
  align-items: center;
  margin-right: 2rem;
  width: 14vw;
}

.metricsContainer {
  margin-top: 1rem;
}

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.cPointer {
  cursor: pointer;
}

.header {
  margin-bottom: 1rem;
  font-size: 1.3rem !important;
  font-weight: bold !important;
  text-align: center;
  color: #2c358d !important;
}
.CustomerName {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  color: #403E3C !important;
}
.headerc {
  margin-bottom: 1rem;
  font-size: 1.3rem !important;
  font-weight: bold !important;
  padding-left: 1.5rem;
  padding-top: 1rem;
  color: #403E3C !important;
}

.item_details {
  width: 70%;
  margin: 0.3rem auto 0 auto;
  padding: 1rem;
}

.item_details_text {
  color: black !important;
  font-weight: bold !important;
  margin-bottom: 0.3rem !important;
}

.item_detail {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}

.item_history {
  width: 90%;
  margin: 0 auto;
}

.galleryCategories {
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  width: 40vw;
  margin-bottom: 2rem;
  cursor: pointer;
}

.listItemSelected {
  color: white;
  background-color: #7dc22b !important;
}

.listItemSelected {
  border-bottom: 2px solid gray;
  background-color: $primaryBackground !important;

  .listItemText {
    color: black;
    font-weight: 800 !important;
  }
}

.root {
  font-size: 0.8rem;
  color: $secondaryTextColor;
  width: auto !important;
  padding-bottom: 3px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 0.3rem;

  &:hover {
    color: black;
  }
  &:selected {
    &:hover {
      color: "";
    }

    .listItemText {
      color: $lightBlue;
    }
  }
}

.dashboard_datepicker {
  padding: 2px !important;
}

.payment_credit {
  background-color: #faf0f0;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem !important;
  color: $deleteRed;
}

.payment_paid {
  border-radius: 0.5rem;
  background-color: #effcde;
  padding: 0.1rem 0.5rem !important;
  color: #7dc22b;
}

.inputSelect {
  height: 1.2rem;
  border-radius: 5px !important;
  background-color: white;
  border: none !important;
  // padding: 0.2rem 0 0.2rem 1rem;
  height: 35px;
  padding: 8px !important;
  width: 100%;
  color: textColor;
  font-size: 0.8rem !important;

  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.table {
  // minWidth: 530;
  width: 100%;
}
.customTable {
  .MuiTableCell-sizeSmall {
    padding: 6px 0px 6px 16px; // <-- arbitrary value
  }
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.confirmButton {
  background-color: red;
  color: white;
}

.cancelButton {
  background-color: #8388a4;
  color: white;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataContainer {
  height: 70vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataSvg {
  height: 10rem;
  width: 10rem;
  margin-bottom: 2rem;
}

.filterButton {
  background-color: black;
  color: white;
  font-size: 0.9rem;
  text-transform: none;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;

  &:hover {
    background-color: black;
  }
}

.buttonContainer {
  width: 95%;
}

.formButton {
  background-color: darkBlue;
  color: white;
  font-size: 0.9rem;
  text-transform: none;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  &:hover {
    background-color: darkBlue;
  }
}

.loadingContainer {
  height: 23rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formHeader {
  width: 100%;
  height: 3rem;
  font-size: 2rem;
  padding: 16px 0;
  // borderBottom: `2px solid #e0e0e0`
}

.formHeaderText {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.form-content {
  padding: 16px 20px;
  width: 35rem;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circularProgress {
  color: white;
  height: 7px;
  width: 10px;
}

.projectCard {
  height: 14rem;
  width: 12rem;
  margin: 2rem;
  display: inline-block;
}

.main {
  background-color: #f4f5f7;
  // padding-left: 2rem;
  // padding-right: 2rem;
  height: 100vh;
  overflow-y: scroll;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mg1Vertical {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mg2Vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.maxWidth {
  width: 100%;
}

.addNewProjectContainer {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectsContainer {
  height: 65vh;
  margin: 1rem 3rem;
}

.projectCardlink {
  background-color: cyan;
  text-decoration: none;
}

.projectCardImage {
  width: 100%;
  height: 10rem;
  background-color: orangeRed;
}

.projectCardName {
  text-align: center;
  margin-top: 0.5rem;
}

.left {
  display: flex;
  justify-content: end;
}

.homeHeader {
  width: 100%;
  height: 2rem;
  padding-top: 1rem;
}

.header {
  font-size: 2rem;
  font-weight: bold;
}

.newProjectBtn {
  background-color: $primaryBlue;
  border-radius: 0.5rem;
  margin-left: auto;
  color: white;
  &:hover {
    background-color: $primaryBlue;
  }
}

.profileContainer {
  height: 60vh;
  width: 100%;
  margin: 3rem auto;
}

.profileTitle {
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-bottom: 1.2rem !important;
}

.profilePhotoCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 0;
  box-shadow: 0 3px 13px $primaryGrey;
}

.actionsIcon {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.profilePicAvatar {
  height: 2rem;
  width: 2rem;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.profileName {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  font-family: Poppins;
}

.profileSecondaryText {
  font-size: 0.9rem;
  color: $textColor;
  font-family: Poppins;
}

.profileInput {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 15px;
  color: $textColor;
}

.profileLeftSection {
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  background-color: white;
}

.profileTopSection {
  padding: 2rem 1.5rem;
}

.profileMainSection {
  padding: 1rem 1.5rem;
}

.uploadImage {
  color: $primaryBlue;
  // font-family: Poppins;
}

.inputComponent {
  margin: 1rem 0px;
  border-radius: 12px;
}

.label {
  font-size: 0.8rem;
  margin: 0.2rem;
}

.input {
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  padding-left: 1rem;
  color: textColor;
  font-size: 0.8rem;
}

.inputSelect {
  height: 35px;
  border-radius: 10px !important;
  border: 1px solid grey;
}

.formButton {
  background-image: linear-gradient(to right bottom, #9ac7fd, #4084d8);
  color: white;
  font-size: 0.7rem;
  padding: 4px 2rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}

.formHeader {
  width: 100%;
  height: 3rem;
  font-size: 2rem;
  padding: 16px 0;
  border-bottom: 2px solid #e0e0e0;
}

.formHeaderText {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.formContent {
  padding: 16px 20px;
  width: 35rem;
}

.loadingContainer {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100vh;
  width: 100vw;
}

.sidebar {
  background-color: white;
  overflow-y: hidden;
  border-right: 1px solid rgb(218, 217, 217);

}

.imageContainer {
  height: 5rem;
  width: 100%;
  margin: 1.5rem 0;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.topActions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.topActionsLeft {
  display: flex;
}

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.cPointer {
  cursor: pointer;
}

.topActionsRight {
  display: flex;
  // width: 50%;
  gap: 2rem;
}

.newButton {
  background-color: $primaryBlue;
  color: white;
  margin-left: 1rem;
  &:hover {
    background-color: $primaryBlue;
  }
}

.genericButton {
  background-color: $primaryBlue;
  color: white;
  text-transform: none;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: Poppins;
  &:hover {
    background-color: $primaryBlue;
  }
}

.normalButton {
  background-color: white !important;
  border: 2px solid transparent !important;
  color: $textColor !important;
  &:hover {
    background-color: white !important;
    border: 2px solid $primaryBlue !important;
  }
}

.search {
  position: relative;
  border-radius: 2rem;
  background-color: white;
  // &:hover {
  //     background-color: alpha(theme.palette.common.white; 0.25);
  // };
  // margin-right: theme.spacing(2);
  margin-left: 0;
  width: 100%;

  // [theme.breakpoints.up(sm)] {
  //     // margin-left: theme.spacing(3);
  //     width: auto;
  // };
}

.searchIcon {
  padding-left: 1rem;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
}
.inputInput {
  padding: 10px !important;
  border-radius: 0px !important;
  padding-left: 3rem !important;
}

// .inputInput {
//   padding: theme.spacing(1; 1; 1; 0);
//   // vertical padding + font size from searchIcon
//   padding-left: `calc(1em + ${theme.spacing(4)}px)`;
//   transition: theme.transitions.create(width);
//   width: 100%

//   [theme.breakpoints.up(md)] {
//       width: 20ch;
//   };
// }

.banner {
  // overflow-x: scroll !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  display: flex !important;
}

.metricsCardContainer {
  display: inline;
}

.metricsCard {
  background-color: white;
  height: 7rem;
  width: 16.7rem !important;
  border-radius: 1rem;
  padding: 1rem;
  margin-right: 2rem;
}

.metricsCardFlex {
  height: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
}

.metricsCardRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.metricsCardTitle {
  color: $secondaryTextColor;
  font-size: 0.8rem !important;
}

.metric {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  line-height: 2.5rem !important;
}

.projectViewDrawer {
  width: 40vw !important;
}


.topContainer {
  display: flex;
  justify-content: space-between;
  margin: 2rem 1rem;
}

.primaryButton {
  background-color: $primaryColor !important;
  color: white !important;
  // width: 10rem;
  font-size: 0.7rem !important;
  margin: 0 0.1rem !important;
}

.exportExcelButton {
  background-color: white !important;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  // width: 10rem;
  font-size: 0.7rem !important;
  margin: 0 0.1rem !important;
}

.addButton {
  background-color: $primaryBackground !important;
  color: $textColor !important;
  border: 1px solid $primaryGreen !important;
  border-radius: 10px !important;
  width: 15rem;
  right: 0px;
  font-size: 0.8rem !important;
}

.alignRight {
  display: flex;
  justify-content: right;
  align-items: center;
}

.searchComponent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1rem auto;
}

.tableTopActions {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid $primaryBackground;
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.item_details {
  width: 70%;
  margin: 0.3rem auto 0 auto;
  padding: 1rem;
}

.item_details_text {
  color: black !important;
  font-weight: bold !important;
  margin-bottom: 0.3rem !important;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.statsCardContainer {
  width: 98%;
  margin: 0 auto 0 auto;
}

.dashboardCard {
  background-color: white;
  border-radius: 0.7rem;
  height: 11rem;
  overflow: hidden;
  padding: 1rem;
}

.dashboardCard_top {
  display: flex;
  justify-content: space-between;
}

.dashboardCard_label {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.dashboardCard_metric {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardCard_metric_value {
  font-size: 2rem !important;
  font-weight: bold !important;
}

.dashboardCard_text_metric {
  display: flex;
  align-items: center;
}

.dashboardCard_text {
  color: $textColor !important;
  margin-left: 0.3rem !important;
}

.notifications {
  background-color: white;
  border-radius: 0.7rem;
  height: 25rem;
}

.chart_container {
  background-color: white;
  border-radius: 0.7rem;
  height: 27rem;
}

.formDocument {
  background-color: white;
  width: 80%;
  margin: 1.8rem auto;
  border: 2px dashed grey;
  border-radius: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem !important;
}

.dashboard_skeleton_card {
  height: 8.3rem !important;
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 1rem !important;
}

.newPurchaseContainer {
  height: 90vh;
  width: 80vw;
}

.paper {
  min-width: 70vw !important;
  min-height: 85vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

.confirmButton {
  background-color: $primaryGreen !important;
  margin: 2.4rem 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.profileContainer {
  height: 60vh;
  width: 100%;
  margin: 3rem auto 10rem auto;
}

.profileTitle {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin-bottom: 1.2rem !important;
  text-align: center;
  font-family: Poppins;
}

.profilePhotoCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding-top: 2rem;
}

.profilePicAvatar {
  height: 2rem;
  width: 2rem;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.profileName {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.profileSecondaryText {
  font-size: 0.9rem;
  color: $textColor;
}

.profileInput {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 15px;
  color: $textColor;
}

.profileLeftSection {
  border-radius: 0.5rem;
  margin-bottom: 0px;
  background-color: white;
}

.profileTopSection {
  padding: 1rem 1.5rem;
}

.profileMainSection {
  padding: 1rem 1.5rem;
}
.genericForm_column {
  padding: 0rem 4rem 0rem 4rem;
  position: relative;
  bottom: 2rem;
}

.changePasswordContainer {
  width: 100%;
  background-color: white;
  padding: 0 4rem 0 4rem;
  margin: 0 auto 6rem auto;
}

.productCard {
  background-color: white;
  margin: 0.8rem 1.2rem;
  height: 13.5rem;
  width: 13rem;
  overflow: hidden;
  border-radius: 1rem;
  padding: 0.4rem;
  cursor: pointer;
  position: relative;
  box-shadow: 5px 5px 10px #c4c4c4;
}

.productCardTop {
  display: flex;
  margin-bottom: 1rem;
}

.productCardImage {
  height: 70%;
  width: 40%;
  border: 1px solid $primaryGrey2;
  margin-right: 1rem;
}

.productName {
  font-weight: 700 !important;
  margin-top: 0.5rem !important;
}

.productDesc {
  color: $textColor;
  margin-top: 0.5rem !important;
  font-size: 0.8rem !important;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow-y: scroll;
}

.tabs {
  border-bottom: 2px solid #c4c4c4;
  display: inline-block;
}

.listItems {
  display: flex;
}

.listItemSelected {
  .listItemText {
    background-color: $lightBlue !important;
    color: $textColor;
  }
}

.listItem {
  &:hover {
    border-bottom: 1px solid $lightBlue;
  }
}

.listItemRoot {
  color: $textColor;
  cursor: pointer;
  font-size: 0.7rem !important;
}

.myAppBar {
  width: 100%;
  padding: 0.2rem 1rem;
  background-color: white;
  border-bottom: 2px solid $primaryGrey3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mg1Vertical {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mg2Vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.userCard {
  background-color: white;
  margin: 0.8rem 1.5rem;
  height: 10rem;
  width: 20rem;
  display: flex;
  border-radius: 1rem;
  padding: 0.7rem;
  box-shadow: 5px 5px 10px #c4c4c4;
}

.userCardLeft {
  flex-basis: 35%;
}

.userCardRight {
  width: 100%;
}

.userCardName {
  font-weight: 700 !important;
  text-align: center;
  font-size: 1.2rem !important;
  margin-bottom: 1rem !important;
}

.userCardLabel {
  color: $lightTextColor !important;
  font-size: 0.9rem !important;
  font-family: Poppins;
}

.userCardValue {
  font-size: 0.9rem !important;
}

.supplierCard {
  background-color: white;
  width: 20rem;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 5px 5px 10px #c4c4c4;
  margin: 1rem;
  max-height: 12.5rem;
}
